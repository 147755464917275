import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import { TrackingEvent, useTrackingService } from '~/services';
import { useCompaniesStore } from '~/stores/companies.store';
import { useRolesStore } from '~/stores/roles.store';
import { Stopwatch } from '~/utils';

import { AxiosApiError } from '../api';
import { useAppVersion } from './application-version.composables';

export type TrackingOptions = {
	companyId?: number;
	error?: AxiosApiError;
	stopwatch?: Stopwatch;
};

export function useTracking(): {
	setUserProperties: (customProperties?: Record<string, any>) => void;
	track: (event: TrackingEvent, conf?: TrackingOptions, customProperties?: Record<string, any>) => void;
} {
	const { setUserProperties: setPostHogUserProperties, trackEvent } = useTrackingService();
	const { getTrackingContext } = useCompaniesStore();
	const { roleTrackingContext } = storeToRefs(useRolesStore());
	const { version } = useAppVersion();

	const { currentRoute } = useRouter();

	const setUserProperties = (userProperties: Record<string, any>) => setPostHogUserProperties(userProperties);

	const track = (event: TrackingEvent, conf: TrackingOptions = {}, customProperties: Record<string, any> = {}) => {
		let props: Record<string, any> = {
			...customProperties,
			...roleTrackingContext.value,
			page_name: currentRoute.value?.name,
			$app_version: version.value
		};
		if (conf.companyId != null) {
			props = { ...props, ...getTrackingContext(conf.companyId) };
		}
		if (conf.error != null) {
			props = { ...props, error: getTrackingErrorMessage(conf.error) };
		}
		if (conf.stopwatch != null) {
			props = { ...props, total_time: conf.stopwatch?.stop() };
		}
		trackEvent(event, props);
	};

	return { setUserProperties, track };
}

function getTrackingErrorMessage(err: AxiosApiError): string {
	return err?.response?.data?.error ?? err?.message;
}
