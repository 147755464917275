import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';

export const useLoadingService = defineStore('loading-service', () => {
	const _runningCalls: Ref<number> = ref(0);

	const isLoading = computed(() => _runningCalls.value > 0);

	const addRunningCall = () => {
		_runningCalls.value++;
	};

	const removeRunningCall = () => {
		if (_runningCalls.value > 0) {
			_runningCalls.value--;
		} else {
			console.warn('Trying to remove a running call, but there are no running calls');
		}
	};

	return { isLoading, addRunningCall, removeRunningCall };
});
