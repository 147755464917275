import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const AdminPayslipsRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_PAYSLIPS,
	path: 'payslips',
	component: () => import('./admin-payslips/admin-payslips-list.vue'),
	meta: {
		isForbidden: () => computed(() => !useFeatures().hasPayslipFullCompanyViewFeature.value)
	}
};

export const SalaryTransferRoute: RouteRecordRaw = {
	name: RouteKeys.SALARY_TRANSFER,
	path: 'salary-transfer',
	component: () => import('./salary-transfer/salary-transfer.vue'),
	meta: {
		isForbidden: () => computed(() => !useFeatures().hasSalaryTransferFeature.value)
	}
};

export const AdminDocumentsRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_DOCUMENTS,
	path: '/admin-documents',
	components: {
		default: () => import('./admin-documents.vue'),
		...defaultLayoutComponents
	},
	children: [AdminPayslipsRoute, SalaryTransferRoute],
	redirect: () => (useFeatures().hasPayslipFullCompanyViewFeature.value ? AdminPayslipsRoute : SalaryTransferRoute),
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());

			return computed(
				() =>
					Devices().isMobile ||
					!isAdmin.value ||
					!(useFeatures().hasPayslipFullCompanyViewFeature.value || useFeatures().hasSalaryTransferFeature.value)
			);
		},
		isNavEntry: true,
		label: 'admin.navigation.documents',
		icon: 'folder',
		requiresAuth: true,
		order: 4
	}
};
