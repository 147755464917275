import { defineStore, storeToRefs } from 'pinia';
import { Observable, tap } from 'rxjs';
import { ComputedRef, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import { MeetingsProviderUserStatus, getMeetingsProviderUserStatus$ } from '~/api/meetings';

import { useCompanySelectionStore } from './company-selection.store';
import { Clearable } from './store.domain';

export type MeetingStore = Clearable & {
	fetchProvisioningUserStatus$: (companyId: number, invalidate?: boolean) => Observable<MeetingsProviderUserStatus>;
	provisioningByCompanyIds: ComputedRef<Record<number, MeetingsProviderUserStatus>>;
	isMeetingRouteForbidden: ComputedRef<boolean>;
	provisionedCompanyIds: ComputedRef<number[]>;
};

export const useMeetingStore = defineStore<'meetings', MeetingStore>('meetings', () => {
	const { selectedCompanyIds } = storeToRefs(useCompanySelectionStore());

	const _provisioningByCompanyIds = ref<Record<number, MeetingsProviderUserStatus>>({});

	const selectedCompanyIdsProvisioned = computed(() =>
		selectedCompanyIds.value.filter(id => _provisioningByCompanyIds.value?.[id]?.exists)
	);

	const provisionedCompanyIds = computed(() => selectedCompanyIdsProvisioned.value);

	const { cache$: cacheProvisioningUserStatus$, clearCache: clearProvisioningUserStatusCache } = useHttpCache<
		number,
		MeetingsProviderUserStatus
	>();

	const clear = () => {
		clearProvisioningUserStatusCache();
		_provisioningByCompanyIds.value = {};
	};

	const _getMeetingsProviderUserStatus$ = (companyId: number, invalidate = false) => {
		if (invalidate) clearProvisioningUserStatusCache();
		const fetch$ = getMeetingsProviderUserStatus$(companyId).pipe(
			tap(provisioning => (_provisioningByCompanyIds.value = { ..._provisioningByCompanyIds.value, [companyId]: provisioning }))
		);
		return cacheProvisioningUserStatus$(companyId, fetch$);
	};

	const isMeetingRouteForbidden = computed(() => {
		return selectedCompanyIdsProvisioned.value.length === 0;
	});

	return {
		provisioningByCompanyIds: computed(() => _provisioningByCompanyIds.value),
		fetchProvisioningUserStatus$: _getMeetingsProviderUserStatus$,
		isMeetingRouteForbidden,
		provisionedCompanyIds,
		clear
	};
});
