import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';

export const GenerateResetPasswordCodeRoute: RouteRecordRaw = {
	name: RouteKeys.UPDATE_PASSWORD_GENERATE_CODE,
	path: 'code',
	component: () => import('./components/generate-code.vue'),
	props: to => ({ firstConnection: to.query.fc === '1' })
};

export const ResetPasswordRouteNoParameter: RouteRecordRaw = {
	name: RouteKeys.UPDATE_PASSWORD_RESET_NO_PARAMETER,
	path: 'reset',
	component: () => import('./components/reset.vue')
};

export const ResetPasswordRoute: RouteRecordRaw = {
	name: RouteKeys.UPDATE_PASSWORD_RESET,
	path: 'reset/:emailAsBase64?',
	props: route => ({ emailAsBase64: route.params.emailAsBase64 }),
	component: () => import('./components/reset.vue')
};

export const UpdatePasswordRoute: RouteRecordRaw = {
	name: RouteKeys.UPDATE_PASSWORD,
	path: '/update-password',
	alias: ['/first-connection', '/forgot-password'],
	component: () => import('./update-password.vue'),
	children: [GenerateResetPasswordCodeRoute, ResetPasswordRouteNoParameter, ResetPasswordRoute],
	redirect: () => GenerateResetPasswordCodeRoute,
	meta: {
		public: true
	}
};
