import { createVuetify } from 'vuetify';
import 'vuetify/styles';

import { vuetifyIcons } from './icons';
import { vuetifyTheme } from './theme';

const vuetify = createVuetify({
	display: {
		mobileBreakpoint: 'sm',
		thresholds: {
			xs: 0,
			sm: 1024,
			md: 1280,
			lg: 1536,
			xl: 1920
		}
	},
	icons: vuetifyIcons,
	theme: vuetifyTheme
});

export { vuetify, vuetifyIcons, vuetifyTheme };
