import Bowser from 'bowser';

export function Devices() {
	const browser = Bowser.getParser(window.navigator.userAgent);
	const platform = browser.getPlatform();
	const os = browser.getOS();

	const isMobile = platform.type === 'mobile';
	const isTablet = platform.type === 'tablet';
	const isDesktop = platform.type === 'desktop';
	const isAndroid = os.name === 'Android';
	const isIOS = os.name === 'iOS';
	const isChrome = browser.getBrowserName() === 'Chrome';

	return {
		isMobile,
		isTablet,
		isDesktop,
		isAndroid,
		isIOS,
		isChrome
	};
}
