import { ISODateString, ISODateTimeString } from '../defs';
import { LeaveDaysTypeFamily } from './types/leave-days-types.dto';

export enum AdminLeaveDaysSearchMode {
	PAYROLL = 'PAYROLL'
}

export enum State {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REFUSED = 'REFUSED',
	CANCELLED = 'CANCELLED',
	TRANSMITTED = 'TRANSMITTED',
	UNKNOWN = 'UNKNOWN'
}

export enum LeaveDaysDateOptions {
	FULL = 'FULL',
	HALFDAY = 'HALFDAY',
	HOURS = 'HOURS'
}

export type LeaveDaysDTO = {
	approvedAt?: ISODateTimeString;
	approvedBy: string;
	belongsToPrincipal: boolean;
	canApplyActions?: boolean;
	comment: string;
	companyId?: number; // FIXME this is optional as it's populated only when in manager views
	employeeId: number;
	employeeName: string;
	employeeFirstName: string;
	employeeLastName: string;
	end: ISODateString;
	externalId: number;
	id: string;
	isCancellable: boolean;
	isDeletable: boolean;
	isHalfDay: boolean;
	isHours: boolean;
	hours: number;
	isInPayslip: boolean;
	isRestricted: boolean;
	label: string;
	requestedAt: ISODateTimeString;
	requestedBy: string;
	start: ISODateString;
	status: State;
	typeCode: string;
	typeGroupLabel: LeaveDaysTypeFamily;
};

export type LeaveDaysCreationRequest = {
	// TODO employee ID is probably needed only when you create a leave day for a managed user, otherwise, your own company ID is sufficient
	typeCode: string;
	comments?: string;
	companyId?: number;
	employeeId?: number;
	end?: Date;
	isEntryInHour: boolean;
	isHalfDay: boolean;
	start: Date;
	dateOption: LeaveDaysDateOptions;
	hours?: string;
};

export type LeaveDaysResponseDTO = {
	isSuccess: boolean;
};

export type LeaveDayIdsRequest = {
	leaveDayIds: Array<number>;
};

export type LeaveDaysAdministrationDto = {
	companyId: number;
	leaveDayId: number;
	employeeId?: number;
};

export type EmployeeLeaveDaysRequest = {
	employeeId: number;
	leaveDaysIds: Array<number>;
};

export type AdminLeaveDaysSearchRequest = {
	employeeIds?: Array<number>;
	period: ISODateString;
	mode: AdminLeaveDaysSearchMode;
};

export type LeaveDaysBulkActionsAsAdminRequest = {
	employeeLeaveDaysRequests: Array<EmployeeLeaveDaysRequest>;
	sendValidationEmail: boolean;
	returnEmailToModify: boolean;
};
