import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { mapBy } from '@silae/helpers';
import {
	CompanyInformationDTO,
	EmployeeCompanyInformationDTO,
	listEmployeeCompaniesInformation$,
	listManagedCompaniesInformation$
} from '~/api';
import { Clearable } from '~/stores/store.domain';

export type CompanyInformationStore = Clearable & {
	getEmployeeCompanyInformation$: (companyId: number) => Observable<EmployeeCompanyInformationDTO>;
	informationPerEmployeeCompany: ComputedRef<Map<number, EmployeeCompanyInformationDTO>>;
	informationPerManagedCompany: ComputedRef<Map<number, CompanyInformationDTO>>;
	listEmployeeCompaniesInformation$: (companyIds: Array<number>) => Observable<Array<EmployeeCompanyInformationDTO>>;
	listManagedCompaniesInformation$: (companyIds: Array<number>) => Observable<Array<CompanyInformationDTO>>;
};

export const useCompanyInformationStore = defineStore<'company-information-store', CompanyInformationStore>(
	'company-information-store',
	() => {
		const employeeCompaniesInformation: Ref<Array<EmployeeCompanyInformationDTO>> = ref([]);
		const managedCompaniesInformation: Ref<Array<CompanyInformationDTO>> = ref([]);

		const clear = () => {
			employeeCompaniesInformation.value = [];
			managedCompaniesInformation.value = [];
		};

		const informationPerEmployeeCompany = computed(() => mapBy<number>(employeeCompaniesInformation.value, 'companyId'));
		const informationPerManagedCompany = computed(() => mapBy<number>(managedCompaniesInformation.value, 'companyId'));

		const _listEmployeeCompaniesInformation$ = (companyIds: Array<number>) => {
			const uncachedIds = companyIds.filter(id => !informationPerEmployeeCompany.value?.has(id));

			const list$ = uncachedIds.length > 0 ? listEmployeeCompaniesInformation$(uncachedIds) : of([]);

			return list$.pipe(
				tap(data => (employeeCompaniesInformation.value = [...employeeCompaniesInformation.value, ...data])),
				switchMap(data => of(employeeCompaniesInformation.value.filter(info => companyIds.includes(info.companyId))))
			);
		};

		const _listManagedCompaniesInformation$ = (companyIds: Array<number>) => {
			const uncachedIds = companyIds.filter(id => !informationPerManagedCompany.value?.has(id));

			const list$ = uncachedIds.length > 0 ? listManagedCompaniesInformation$(uncachedIds) : of([]);

			return list$.pipe(
				tap(data => (managedCompaniesInformation.value = [...managedCompaniesInformation.value, ...data])),
				switchMap(data => of(managedCompaniesInformation.value.filter(info => companyIds.includes(info.companyId))))
			);
		};

		const getEmployeeCompanyInformation$ = (companyId: number) =>
			_listEmployeeCompaniesInformation$([companyId]).pipe(switchMap(([data]) => of(data)));

		return {
			clear,
			getEmployeeCompanyInformation$,
			informationPerEmployeeCompany: computed(() => informationPerEmployeeCompany.value),
			informationPerManagedCompany: computed(() => informationPerManagedCompany.value),
			listEmployeeCompaniesInformation$: _listEmployeeCompaniesInformation$,
			listManagedCompaniesInformation$: _listManagedCompaniesInformation$
		};
	}
);

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCompanyInformationStore, import.meta.hot));
