import { Observable } from 'rxjs';

import { COMPANY_BASE_URL, ISODateString } from '../defs';
import { useBackendHttpService } from '../http';
import {
	BonusRequest,
	EmployeeVariableBonusDetailsAndDefinitionsDTO,
	VariableBonusUpsertRequest,
	VariableBonusUpsertResponse
} from './bonuses.dto';
import { EmployeeID, JobID } from './employee.dto';
import { EmployeeVariableHoursDetailsAndDefinitionsDTO, VariableHoursUpsertRequest, VariableHoursUpsertResponse } from './hours.dto';
import {
	ValidateVariableElementsRequest,
	VariableElementsSearchRequest,
	VariableElementsValidationProcessInformationDTO,
	VariableElementsCommentDTO,
	VariableElementsCommentsRequest,
	VariableElementsCommentCreateOrUpdateRequest,
} from './variable-elements.dto';

const API = 'variable-elements';

export function fetchEmployeeBonusDetailsAndDefinitions$(
	companyId: number,
	request: BonusRequest
): Observable<EmployeeVariableBonusDetailsAndDefinitionsDTO> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/${API}/bonuses/_search`, request);
}

export function updateEmployeeBonus$(
	companyId: number,
	period: ISODateString,
	employeeId: EmployeeID,
	jobId: JobID,
	request: VariableBonusUpsertRequest
): Observable<VariableBonusUpsertResponse> {
	const { http } = useBackendHttpService();
	return http.put(`${COMPANY_BASE_URL}/${companyId}/${API}/bonuses/employees/${employeeId}/jobs/${jobId}/period/${period}`, request);
}

export function fetchEmployeeHoursDetailsAndDefinitions$(
	companyId: number,
	request: VariableElementsSearchRequest
): Observable<EmployeeVariableHoursDetailsAndDefinitionsDTO> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/${API}/hours/_search`, request);
}

export function updateEmployeeHours$(companyId: number, request: VariableHoursUpsertRequest): Observable<VariableHoursUpsertResponse> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/${API}/hours`, request);
}

export function validateVariableElements$(companyId: number, request: ValidateVariableElementsRequest): Observable<void> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/${API}/validation`, request);
}

export function fetchValidationProcessInformation$(
	companyId: number,
	period: ISODateString
): Observable<VariableElementsValidationProcessInformationDTO> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${API}/validation?period=${period}`);
}

export function fetchVariableElementsComments$(companyId: number, request: VariableElementsCommentsRequest): Observable<Array<VariableElementsCommentDTO>> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/${API}/comments/_search`, request);
}

export function createVariableElementsComment$(companyId: number, request: VariableElementsCommentCreateOrUpdateRequest): Observable<VariableElementsCommentDTO> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/${API}/comments`, request);
}

export function deleteVariableElementsComment$(companyId: number, commentId: number): Observable<void> {
	const { http } = useBackendHttpService();
	return http.delete(`${COMPANY_BASE_URL}/${companyId}/${API}/comments/${commentId}`);
}

export function updateVariableElementsComment$(companyId: number, commentId: number, request: VariableElementsCommentCreateOrUpdateRequest): Observable<void> {
	const { http } = useBackendHttpService();
	return http.put(`${COMPANY_BASE_URL}/${companyId}/${API}/comments/${commentId}`, request);
}
