import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

export function useDateFormatter(): { asDateTimeShort: (time: DateTime) => string; asDateShort: (time: DateTime) => string } {
	const { t } = useI18n();

	const asDateTimeShort = (datetime: DateTime) =>
		datetime?.isValid
			? t('common.format.date-time-short', {
					date: datetime.toLocaleString(DateTime.DATE_SHORT),
					time: datetime.toLocaleString(DateTime.TIME_SIMPLE)
				})
			: null;

	const asDateShort = (datetime: DateTime) => (datetime?.isValid ? datetime.toLocaleString(DateTime.DATE_SHORT) : null);

	return {
		asDateTimeShort,
		asDateShort
	};
}
