export enum MediaContentTypes {
	CSV = 'text/csv',
	JPEG = 'image/jpeg',
	OTHER = 'application/octet-stream',
	PDF = 'application/pdf',
	PNG = 'image/png',
	ZIP = 'application/zip'
}

export enum FileFormat {
	JPEG = 'jpeg',
	JPG = 'jpg',
	PDF = 'pdf',
	PNG = 'png',
	ZIP = 'zip'
}

export enum AuthenticationExceptionCode {
	INVALID_TOKEN = 'INVALID_TOKEN',
	NON_EXISTENT_USER = 'NON_EXISTENT_USER',
	NO_RIGHTS = 'NO_RIGHTS',
	PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
	PASSWORD_SECURITY_POLICY = 'PASSWORD_SECURITY_POLICY',
	UNKNOWN = 'UNKNOWN'
}
