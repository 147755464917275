import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import { FileFormat, MediaContentTypes } from '~/utils/enums';


function _download(
	filename: string,
	data: BlobPart | BlobPart[] | ArrayBuffer,
	extension?: string,
	mediaType?: string,
	encoding?: string
): void {
	const blob = new Blob(Array.isArray(data) ? data : [data], {
		type: `${mediaType ? mediaType : undefined}${encoding ? ';' + encoding : undefined}`
	});
	const completeFileName = filename + `${extension ? '.' + extension : ''}`;
	saveAs(blob, completeFileName);
}

/**
 * Opens download prompt command for downloading a file
 *
 * @param fileName file name
 * @param data file content
 * @param extension file extension
 */
export function downloadTextFile(fileName: string, data: BlobPart | BlobPart[] | ArrayBuffer, extension: string): void {
	return _download(fileName, data, extension, `text/${extension}`, 'charset=utf-8');
}

/**
 * Download an image
 * @param filename name
 * @param data content
 * @param extension
 */
export function downloadImage(filename: string, data: BlobPart | BlobPart[] | ArrayBuffer, extension: string): void {
	return _download(filename, data, extension, `image/${extension}`);
}

/**
 * Download a PDF file
 * @param filename name
 * @param data content
 */
export function downloadPDF(filename: string, data: BlobPart | BlobPart[] | ArrayBuffer): void {
	return _download(filename, data, 'pdf', 'application/pdf');
}

/**
 * Download a CSV file
 * @param fileName CSV name
 * @param data CSV content
 */
export function downloadCSV(fileName: string, data: BlobPart | BlobPart[] | ArrayBuffer): void {
	return _download(fileName, data, 'csv', `text/csv`, 'charset=utf-8');
}

/**
 * Download a ZIP file
 * @param name name
 * @param data content
 */
export function downloadZIP(name: string, data: BlobPart | BlobPart[] | ArrayBuffer): void {
	return _download(name, data, FileFormat.ZIP, MediaContentTypes.ZIP);
}

/**
 * Download an XLS file
 * @param filename name
 * @param data content
 * @param extension
 */
export function downloadXSL(filename: string, data: BlobPart | BlobPart[] | ArrayBuffer, extension: string): void {
	return _download(filename, data, extension, `image/${extension}`);
}

export function extractFilenameFromContentDispositionHeader(response: AxiosResponse) {
	const match = response.headers['content-disposition']?.match(/filename="?([^"]+)"?/);
	return match ? match[1] : null;
}
