import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { error } from '@silae/helpers';
import { HealthDTO, checkHealth$ } from '~/api';

export type BackendAppStore = {
	backendHealth: ComputedRef<HealthDTO>;
	fetchCheckHealth$: () => Observable<HealthDTO>;
};

export const useBackendAppStore = defineStore<'backend-app', BackendAppStore>('backend-app', () => {
	const _backendHealth: Ref<HealthDTO> = ref();

	const _fetchCheckHealth$ = () => {
		if (_backendHealth.value) {
			return of(_backendHealth.value);
		}

		return checkHealth$().pipe(
			catchError(err => of(err)),
			tap((health: HealthDTO) => {
				if (health.status !== 'ok') {
					error('backend down', { health });
				}
				_backendHealth.value = health;
			})
		);
	};

	return {
		backendHealth: computed(() => _backendHealth.value),
		fetchCheckHealth$: _fetchCheckHealth$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useBackendAppStore, import.meta.hot));
