import { Observable } from 'rxjs';

import { ADMIN_BASE_URL, COMPANY_BASE_URL, ISODateString, MANAGER_BASE_URL } from '~/api/defs';
import { useBackendHttpService } from '~/api/http';

import { LeaveDaysCountersDTO } from './leave-days-counters.dto';

const API = 'leave-days-counters';

export function getLeaveDaysCounters$(companyId: number, at?: ISODateString): Observable<Array<LeaveDaysCountersDTO>> {
	const { http } = useBackendHttpService();
	if (at) {
		return http.get(`${COMPANY_BASE_URL}/${companyId}/${API}?at=${at}`);
	}
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${API}`);
}

export function getLeaveDaysCountersForManagedEmployee$(
	companyId: number,
	employeeId: number,
	at?: ISODateString
): Observable<Array<LeaveDaysCountersDTO>> {
	const { http } = useBackendHttpService();
	if (at) {
		return http.get(`${MANAGER_BASE_URL}/${companyId}/${API}?employeeId=${employeeId}&at=${at}`);
	}
	return http.get(`${MANAGER_BASE_URL}/${companyId}/${API}?employeeId=${employeeId}`);
}

export function getLeaveDaysCountersForAdministeredEmployee$(
	companyId: number,
	employeeId: number,
	at?: ISODateString
): Observable<Array<LeaveDaysCountersDTO>> {
	const { http } = useBackendHttpService();
	if (at) {
		return http.get(`${ADMIN_BASE_URL}/${companyId}/${API}?employeeId=${employeeId}&at=${at}`);
	}
	return http.get(`${ADMIN_BASE_URL}/${companyId}/${API}?employeeId=${employeeId}`);
}

export function getLeaveDaysCountersForManagedEmployeeAndLeaveDaysType$(
	companyId: number,
	employeeId: number,
	leaveDaysTypeCode: string
): Observable<Array<LeaveDaysCountersDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${MANAGER_BASE_URL}/${companyId}/${API}?employeeId=${employeeId}&leaveType=${leaveDaysTypeCode}`);
}

export function getLeaveDaysCountersForAdministeredEmployeeAndLeaveDaysType$(
	companyId: number,
	employeeId: number,
	leaveDaysTypeCode: string
): Observable<Array<LeaveDaysCountersDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${ADMIN_BASE_URL}/${companyId}/${API}?employeeId=${employeeId}&leaveType=${leaveDaysTypeCode}`);
}
