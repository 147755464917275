import { State } from '~/api';

enum ChipColors {
	POSITIVE = 'success',
	NEGATIVE = 'error',
	NEUTRAL = 'neutral',
	WARNING = 'warning',
	INFO = 'info',
	BLUE = 'cumulus90',
	EVERGREEN = 'evergreen90',
	PURPLE = 'purple90',
	PINK = 'pink90',
	TERRACOTTA = 'terracotta90',
	YELLOW = 'yellow90',
	GREEN = 'pomme80',
	EMERALD = 'emerald80'
}

function getStateColor(status: State): ChipColors {
	switch (status) {
		case State.APPROVED:
			return ChipColors.POSITIVE;
		case State.PENDING:
			return ChipColors.NEUTRAL;
		case State.REFUSED:
			return ChipColors.NEGATIVE;
		case State.CANCELLED:
			return ChipColors.NEUTRAL;
		case State.TRANSMITTED:
			return ChipColors.INFO;
	}
}

export { getStateColor, ChipColors };
