import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';

export const DownloadFileRoute: RouteRecordRaw = {
	name: RouteKeys.PUBLIC_DOWNLOAD,
	path: '/files/:token',
	components: {
		default: () => import('./download-file.vue')
	},
	props: {
		default: route => ({ token: route.params.token })
	},
	meta: {
		public: true,
		requiresAuth: false
	}
};

export const PublicDownloadExpiredRoute: RouteRecordRaw = {
	name: RouteKeys.PUBLIC_DOWNLOAD_EXPIRED,
	path: '/files/:token/expired',
	components: {
		default: () => import('./download-file-expired.vue')
	},
	meta: {
		public: true,
		requiresAuth: false
	}
};
