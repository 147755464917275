import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';

import { defaultLayoutComponents } from '../layout/layout';

export const HomeRoute: RouteRecordRaw = {
	name: RouteKeys.HOME,
	path: '/home',
	alias: '',
	components: {
		default: () => import('./home.vue'),
		...defaultLayoutComponents
	},
	meta: {
		label: 'home.navigation',
		icon: 'dashboard',
		isNavEntry: true,
		order: 1,
		requiresAuth: true
	}
};
