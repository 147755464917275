import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const AdminAgendaRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_AGENDA,
	path: '/admin-agenda',
	components: {
		default: () => import('~/pages/agenda/agenda.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value || !useFeatures().hasLeaveDayAdminFullFeature.value || Devices().isMobile);
		},
		isNavEntry: true,
		label: 'admin.navigation.agenda',
		icon: 'calendar',
		requiresAuth: true,
		order: 2
	}
};
