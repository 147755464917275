import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useKonamiCode } from '@silae/composables';
import { useFeatures } from '~/composables';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { defaultLayoutComponents } from '../layout/layout';

const { konami } = useKonamiCode();

export const AutomaticEarnedWageAccessesRoute: RouteRecordRaw = {
	name: RouteKeys.AUTOMATIC_EARNED_WAGE_ACCESSES,
	path: '/automatic-earned-wage-accesses',
	components: {
		default: () => import('./automatic-earned-wage-accesses.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isEmployee } = storeToRefs(useRolesStore());
			return computed(() => !isEmployee.value || !useFeatures().hasAutomaticEarnedWageAccessesRequestFeature.value || !konami.value);
		},
		label: 'automatic-earned-wage-access.navigation',
		icon: 'payment-card-arrow',
		isNavEntry: true,
		order: 5,
		requiresAuth: true
	}
};
