import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';

export function getAppInsights(): ApplicationInsights {
	const appInsights = new ApplicationInsights({
		config: {
			connectionString: import.meta.env.MS_APP_INSIGHTS_CONNECTION_STRING,
			disableExceptionTracking: true,
			distributedTracingMode: DistributedTracingModes.W3C,
			enableAutoRouteTracking: true,
			// exclude URLs outside silae.fr from tracking
			excludeRequestFromAutoTrackingPatterns: [/^((?!silae\.fr).)*$/],
			correlationHeaderExcludePatterns: [/^((?!silae\.fr).)*$/]
		}
	});

	appInsights.loadAppInsights();
	return appInsights;
}
