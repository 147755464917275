export const CONGES_PAYES_CODE: string = '300';

export enum LeaveDaysTypeFamily {
	ABSENCE = 'Absence',
	LEGAL = 'Absences légales',
	OTHER = 'Autres',
	UNEMPLOYMENT = 'Chômage',
	BUSINESS_TRAVEL = 'DP',
	FAMILY = 'Famille',
	TRAINING = 'Formation',
	REST = 'Repos',
	HEALTH = 'Santé',
	TELEWORK = 'TT'
}

export type LeaveDaysTypeDTO = {
	code: string;
	label: string;
	groupLabel: LeaveDaysTypeFamily;
	isEntryInHour: boolean;
	isHalfDayAuthorized: boolean;
};
