import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';
import { useMeetingStore } from '~/stores';

import { defaultLayoutComponents } from '../layout/layout';

export const MeetingsRoute: RouteRecordRaw = {
	name: RouteKeys.MEETINGS,
	path: '/meetings',
	components: {
		default: () => import('./meetings.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isMeetingRouteForbidden } = storeToRefs(useMeetingStore());
			return computed(() => isMeetingRouteForbidden.value);
		},
		label: 'interview.title',
		icon: 'messages-round',
		isNavEntry: true,
		order: 30,
		requiresAuth: true
	}
};
