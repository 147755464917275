import { LocationQuery } from 'vue-router';

export function stripLocationQueryParams(query: LocationQuery, paramsToStrip: Array<string>): LocationQuery {
	if (query == null) {
		return;
	}
	const strippedQuery = { ...query };
	paramsToStrip.forEach(param => {
		delete strippedQuery[param];
	});
	return strippedQuery;
}
