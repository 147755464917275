import { watchImmediate } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { Ref } from 'vue';

import { useStorageService } from '~/services';
import { useAuthenticationStore } from '~/stores';

/***
 1. store source ref value in the local storage as ${key}
 2. on authenticate, read from storage and execute provided callback
 */
export function useLocaleStorageSynchronization<T>(source: Ref<T>, key: string, onAuthenticate: (data: T) => void) {
	const { isAuthenticated } = storeToRefs(useAuthenticationStore());
	const { read, write } = useStorageService();

	watchImmediate(source, data => {
		if (isAuthenticated.value) {
			write(key, data);
		}
	});

	watchImmediate(isAuthenticated, isAuthenticated => {
		if (isAuthenticated) {
			const data = read<T>(key);
			onAuthenticate(data);
		}
	});
}
