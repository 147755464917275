<template>
	<v-icon v-bind="$attrs" :icon="value" :size />
</template>

<script lang="ts" setup>
import { error } from '@silae/helpers';
import { computed } from 'vue';
import { VIcon } from 'vuetify/components';
import { $fa, iconAliases } from '~/plugins';

const props = withDefaults(
	defineProps<{
		icon: string | `$${string}`;
		size?: string | number;
	}>(),
	{
		size: 'small'
	}
);

const value = computed(
	() => {
		let val;

		if(props.icon?.startsWith('$')) {
			val = iconAliases?.[props.icon.substring(1)]
		} else {
			val = $fa(props.icon);
		}

		if(val == null) {
			error('missing icon', {icon: props.icon});
		}

		return (val ?? $fa('alien-monster'));
	}
);
</script>
