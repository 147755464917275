import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const ContractualInformationRoute: RouteRecordRaw = {
	name: RouteKeys.ACCOUNT_CONTRACTUAL_INFORMATION,
	path: 'contractual-information',
	component: () => import('./contractual-information.vue'),
	meta: {
		isForbidden: () => {
			const { hasEmployeeRole } = storeToRefs(useRolesStore());
			return computed(() => !hasEmployeeRole.value);
		},
		label: 'account.navigation.contractual-information',
		icon: 'list-checks',
		requiresAuth: true,
		isSettingsNavEntry: true,
		order: 1
	}
};
