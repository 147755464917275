import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Observable } from 'rxjs';

/**
 * @type HttpService
 */
export type HttpService = {
	delete<T = any>(url: string, config?: AxiosRequestConfig, responseMapper?: (res: AxiosResponse) => any): Observable<T>;
	get<T = any>(url: string, config?: AxiosRequestConfig, responseMapper?: (res: AxiosResponse) => any): Observable<T>;
	patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig, responseMapper?: (res: AxiosResponse) => any): Observable<T>;
	post<T = any>(url: string, data?: any, config?: AxiosRequestConfig, responseMapper?: (res: AxiosResponse) => any): Observable<T>;
	put<T = any>(url: string, data?: any, config?: AxiosRequestConfig, responseMapper?: (res: AxiosResponse) => any): Observable<T>;
};

/**
 * @enum RequestMethod
 */
export enum RequestMethod {
	GET = 'get',
	HEAD = 'head',
	POST = 'post',
	PUT = 'put',
	PATCH = 'patch',
	DELETE = 'delete',
	OPTIONS = 'options',
	TRACE = 'trace'
}
