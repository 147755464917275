import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { useHttpCache } from '@silae/composables';
import { fetchInstitutions$ } from '~/api';
import { Institution } from '~/domain';

import { Clearable } from './store.domain';

export type InstitutionStore = Clearable & {
	fetchInstitutions$: (companyId: number, invalidate?: boolean) => Observable<Array<Institution>>;
};

export const useInstitutionStore = defineStore<'institution-store', InstitutionStore>('institution-store', () => {
	const { cache$: cacheInstitutions$, clearCache: clearInstitutionsCache } = useHttpCache<number, Array<Institution>>();

	const clear = () => {
		clearInstitutionsCache();
	};

	const _fetchInstitutions$ = (companyId: number, invalidate?: boolean): Observable<Array<Institution>> => {
		if (invalidate) clearInstitutionsCache();
		return cacheInstitutions$(companyId, fetchInstitutions$(companyId).pipe(map(dtos => dtos.map(dto => ({ ...dto, companyId })))));
	};

	return {
		clear,
		fetchInstitutions$: _fetchInstitutions$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useInstitutionStore, import.meta.hot));
