import { Observable } from 'rxjs';

import { useBackendHttpService } from '~/api/http';

import { ADMIN_BASE_URL } from '../defs';
import { HealthDepartmentAddressDTO } from './institution-health-department.dto';
import { InstitutionDTO } from './institutions.dto';

const API = 'institutions';

export function fetchInstitutions$(companyId: number): Observable<Array<InstitutionDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${ADMIN_BASE_URL}/${companyId}/${API}`);
}

export function fetchInstitutionHealthDepartmentAddress$(
	companyId: number,
	institutionId: number
): Observable<Array<HealthDepartmentAddressDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${ADMIN_BASE_URL}/${companyId}/${API}/${institutionId}/health-department`);
}
