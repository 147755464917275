import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useKonamiCode } from '@silae/composables';
import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

const { konami } = useKonamiCode();

export const AdminAutomaticEarnedWageAccessRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_AUTOMATIC_EARNED_WAGE_ACCESS,
	path: '/admin-earned-wage-access',
	components: {
		default: () => import('./automatic-earned-wage-access.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(
				() =>
					Devices().isMobile || !isAdmin.value || !useFeatures().hasAutomaticEarnedWageAccessesAdminFeature.value || !konami.value
			);
		},
		isSubEntry: true,
		label: 'admin.navigation.automatic-earned-wage-access',
		requiresAuth: true,
		order: 20
	}
};
