<template>
	<v-list>
		<v-list-item>
			{{ $t('help.documentation.before_link_label') }}
			<a class="d-inline-flex align-center" :href="$t('help.link')" target="_blank">
				{{ $t('help.documentation.link_label') }}<fa-icon class="ml-2 text-secondary" icon="open-in-new" size="16" />
			</a>
		</v-list-item>
		<v-divider />
		<v-list-item class="text-label-sm documentation-version">
			<div>{{ $t('help.app_infos.version', { version }) }}</div>
			<div>{{ $t('help.app_infos.update_date', { date }) }}</div>
		</v-list-item>
	</v-list>
</template>

<style lang="scss" scoped>
.documentation-version {
	line-height: 150%;
}
</style>

<script lang="ts" setup>
import { VDivider, VList, VListItem } from 'vuetify/components';

import { useAppVersion } from '~/composables';

import { FaIcon } from '../icons';

const { date, version } = useAppVersion();
</script>
