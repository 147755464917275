import { Observable } from 'rxjs';

import { COMPANY_BASE_URL } from '~/api/defs';
import { useBackendHttpService } from '~/api/http';

import { PayrollPeriodDTO } from './payroll-period.dto';

const API = 'payroll-period';

export function getPayrollPeriod$(companyId: number): Observable<PayrollPeriodDTO> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${API}`);
}
