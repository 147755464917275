import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw, useRouter } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { VariableElementType } from './variable-elements.domain';

export const VariableElementsEditionRoute: RouteRecordRaw = {
	name: RouteKeys.VARIABLE_ELEMENTS_EDITION,
	path: ':type?',
	component: () => import('./variable-elements.vue'),
	props: route => ({ type: route.params.type })
};

export const VariableElementsRoute: RouteRecordRaw = {
	name: RouteKeys.VARIABLE_ELEMENTS,
	path: 'variable-elements',
	components: {
		default: () => import('./variable-elements-router-view.vue'),
		...defaultLayoutComponents
	},
	children: [VariableElementsEditionRoute],
	redirect: to => {
		const params = {
			type: useFeatures().hasVariableElementsBonusesFeature.value ? VariableElementType.BONUSES : VariableElementType.HOURS
		};
		return { ...VariableElementsEditionRoute, params };
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			const { currentRoute } = useRouter();
			const { hasVariableElementsBonusesFeature, hasVariableElementsHoursFeature } = useFeatures();

			// we also need to check the current route parameter to authorize access to one of the tabs [Issue #81747]
			return computed(() => {
				const authorizedTypes: Array<VariableElementType> = [];
				if (hasVariableElementsBonusesFeature.value) authorizedTypes.push(VariableElementType.BONUSES);
				if (hasVariableElementsHoursFeature.value) authorizedTypes.push(VariableElementType.HOURS);

				return (
					Devices().isMobile ||
					!isAdmin.value ||
					!(useFeatures().hasVariableElementsBonusesFeature.value || useFeatures().hasVariableElementsHoursFeature.value) ||
					(currentRoute.value.params?.type != null && !authorizedTypes.some(t => t === currentRoute.value.params?.type))
				);
			});
		},
		isSubEntry: true,
		label: 'admin.navigation.variable-elements.edition',
		requiresAuth: true,
		order: 20
	}
};
