import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import {
	AxiosApiError,
	EmployeeID,
	EmployeeVariableHoursDetailsAndDefinitionsDTO,
	EmployeeVariableHoursDetailsDTO,
	VariableBonusDefinitionDTO,
	VariableElementCode,
	VariableElementsSearchRequest,
	VariableHoursDefinitionDTO,
	VariableHoursDetailsDTO,
	VariableHoursUpsertRequest,
	VariableHoursUpsertResponse,
	fetchEmployeeHoursDetailsAndDefinitions$,
	updateEmployeeHours$
} from '~/api';
// do not shorten import to prevent circular dep
import { useTracking } from '~/composables/tracking.composables';
// do not shorten import to prevent circular dep
import { VariableElementType } from '~/pages/admin/payroll/variable-elements/variable-elements.domain';
import { Stopwatch } from '~/utils';

import { useEmployeesStore } from '../employees';
import { Clearable } from '../store.domain';
import { populateValidationElementsCache } from './variable-elements.store.utils';

export type VariableHoursStore = Clearable & {
	employeeHoursDetails: ComputedRef<Array<EmployeeVariableHoursDetailsDTO>>;
	fetchVariableHoursDetailsAndDefinition$: (
		companyId: number,
		request: VariableElementsSearchRequest,
		invalidateCache?: boolean
	) => Observable<EmployeeVariableHoursDetailsAndDefinitionsDTO>;
	hoursDefinitions: ComputedRef<Array<VariableHoursDefinitionDTO>>;
	updateVariableHoursDetails$: (companyId: number, request: VariableHoursUpsertRequest) => Observable<VariableHoursUpsertResponse>;
};

export const useVariableHoursStore = defineStore<'variable-hours', VariableHoursStore>('variable-hours', () => {
	const { cache$, clearCache } = useHttpCache<string, EmployeeVariableHoursDetailsAndDefinitionsDTO>();

	const { track } = useTracking();

	const { employeesByCompany } = storeToRefs(useEmployeesStore());

	const _employeeHoursDetailsCache: Ref<Array<EmployeeVariableHoursDetailsDTO>> = ref([]);
	const _employeeIDsCache: Ref<Map<EmployeeID, boolean>> = ref(new Map());
	const _hoursDefinitionsCache: Ref<Map<VariableElementCode, VariableBonusDefinitionDTO>> = ref(new Map());

	const clear = () => {
		_employeeHoursDetailsCache.value = [];
		_employeeIDsCache.value = new Map();
		_hoursDefinitionsCache.value = new Map();
		clearCache();
	};

	function fetchVariableHoursDetailsAndDefinition$(
		companyId: number,
		request: VariableElementsSearchRequest,
		invalidateCache?: boolean
	): Observable<EmployeeVariableHoursDetailsAndDefinitionsDTO> {
		if (invalidateCache) {
			_employeeHoursDetailsCache.value = [];
			_employeeIDsCache.value = new Map();
			clear();
			clearCache();
		}

		// fetch hours for employees we haven't fetched yet
		const employeesIds = request.employeesIds.filter(id => !_employeeIDsCache.value.has(id));
		const cheapRequest = { ...request, employeesIds };
		const stopwatch = new Stopwatch();
		const fetch$ = fetchEmployeeHoursDetailsAndDefinitions$(companyId, cheapRequest).pipe(
			tap((hours: EmployeeVariableHoursDetailsAndDefinitionsDTO) => {
				track(
					'Variable Elements Fetched',
					{ companyId, stopwatch },
					{ employees_count: employeesIds.length, variable_element_type: VariableElementType.HOURS }
				);
				populateValidationElementsCache(
					companyId,
					employeesIds,
					hours,
					_hoursDefinitionsCache.value,
					_employeeIDsCache.value,
					_employeeHoursDetailsCache.value,
					employeesByCompany.value
				);
			})
		);

		const cacheKey = JSON.stringify(cheapRequest);
		return employeesIds.length > 0 ? cache$(cacheKey, fetch$) : of(null);
	}

	function updateVariableHoursDetails$(companyId: number, request: VariableHoursUpsertRequest): Observable<VariableHoursUpsertResponse> {
		return updateEmployeeHours$(companyId, request).pipe(
			catchError((err: AxiosApiError<{ value: number }>) => {
				if (err.response.data.context?.value != null) {
					// we can have an error when local data has diverged from server data
					// update local data cache with the one contained in the error from server if any
					const backendValue = err.response.data.context?.value;
					_employeeHoursDetailsCache.value = updateEmployeeDetailsCache(_employeeHoursDetailsCache.value, request, backendValue);
				}
				return throwError(() => err);
			}),
			tap(
				response =>
					(_employeeHoursDetailsCache.value = updateEmployeeDetailsCache(
						_employeeHoursDetailsCache.value,
						request,
						response.value
					))
			)
		);
	}

	return {
		clear,
		employeeHoursDetails: computed(() => _employeeHoursDetailsCache.value.toSorted((a, b) => a.lastName?.localeCompare(b.lastName))),
		fetchVariableHoursDetailsAndDefinition$,
		hoursDefinitions: computed(() => Array.from(_hoursDefinitionsCache.value.values())),
		updateVariableHoursDetails$
	};
});

function updateEmployeeDetailsCache(
	employeesHoursDetails: Array<EmployeeVariableHoursDetailsDTO>,
	request: VariableHoursUpsertRequest,
	backendValue: number
): Array<EmployeeVariableHoursDetailsDTO> {
	return employeesHoursDetails.reduce((updatedCache, employeeVariableDetails) => {
		// unaffected employee or job
		if (employeeVariableDetails.employeeId !== request.employeeId || employeeVariableDetails.jobId !== request.jobId) {
			return [...updatedCache, employeeVariableDetails];
		}

		const value = backendValue ?? request.value;
		let updatedDetails: Array<VariableHoursDetailsDTO>;
		// quick return in case of a bonus creation
		if (request.previousValue == null) {
			// force isEnabled true as we just updated data and optimistically this won't change in the meantime
			updatedDetails = [...employeeVariableDetails.details, { code: request.code, value, isEnabled: true }];
		} else {
			updatedDetails = employeeVariableDetails.details.reduce((updatedDetails, hours) => {
				if (hours.code !== request.code) {
					return [...updatedDetails, hours];
				}
				return [...updatedDetails, { ...hours, value }];
			}, []);
		}

		const updateEmployeeVariableDetails = { ...employeeVariableDetails, details: updatedDetails };
		return [...updatedCache, updateEmployeeVariableDetails];
	}, []);
}

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useVariableHoursStore, import.meta.hot));
