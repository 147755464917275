import { Observable } from 'rxjs';

import { COMPANY_BASE_URL } from '~/api/defs';
import { useBackendHttpService } from '~/api/http';

import { MeetingsCompanyRequest, MeetingsProviderStatus, MeetingsProviderUserStatus, PayUserCount } from './provisioning.dto';

const API = 'meetings/provisioning';

export function provisioningMeetings$(companyId: number, request: MeetingsCompanyRequest): Observable<void> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/${API}`, request);
}

export function getPayUserCount$(companyId: number): Observable<PayUserCount> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${API}/pay-user-count`);
}

export function getMeetingsProviderStatus$(companyId: number): Observable<MeetingsProviderStatus> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${API}/provider-status`);
}

export function getMeetingsProviderUserStatus$(companyId: number): Observable<MeetingsProviderUserStatus> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${API}/provider-user-status`);
}
