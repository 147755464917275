import { Observable } from 'rxjs';

import { useBackendHttpService } from '../http';
import { HealthDTO } from './health.dto';

const API = '/health';

export function checkHealth$(): Observable<HealthDTO> {
	const { http } = useBackendHttpService();
	return http.get(API);
}
