import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable } from 'rxjs';

import { useHttpCache } from '@silae/composables';
import { PayrollPeriodDTO, getPayrollPeriod$ } from '~/api';

import { Clearable } from './store.domain';

export type CompanyContextStore = Clearable & {
	fetchPayrollPeriod$: (companyId: number, invalidate?: boolean) => Observable<PayrollPeriodDTO>;
};

export const useCompanyContextStore = defineStore<'company-context', CompanyContextStore>('company-context', () => {
	const { cache$: cacheBasePeriod$, clearCache: clearBasePeriodCache } = useHttpCache<number, PayrollPeriodDTO>();

	const clear = () => {
		clearBasePeriodCache();
	};

	const fetchPayrollPeriod$ = (companyId: number, invalidate?: boolean): Observable<PayrollPeriodDTO> => {
		if (invalidate) clearBasePeriodCache();
		return cacheBasePeriod$(companyId, getPayrollPeriod$(companyId));
	};

	return {
		clear,
		fetchPayrollPeriod$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCompanyContextStore, import.meta.hot));
