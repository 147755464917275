import { POSITIVE_MOULA_REGEX } from '~/utils/regex.utils';

export function parseMoula(val: string): number {
	if (!val || val.length === 0) {
		return null;
	}

	if (!POSITIVE_MOULA_REGEX.test(val)) {
		return NaN;
	}

	const numericalValue = val.replaceAll(',', '.');
	return parseFloat(numericalValue);
}
