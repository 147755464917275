import { EMPTY } from 'rxjs';
import { App, ComponentPublicInstance } from 'vue';

import { setObservableDefaultErrorHandler } from '@silae/composables';
import { error } from '@silae/helpers';

export function setupErrorHandler(app: App<Element>): void {
	const observableErrorHandler$ = (err: unknown) => {
		error('caught Observable error', err);
		return EMPTY;
	};
	setObservableDefaultErrorHandler(observableErrorHandler$);

	app.config.errorHandler = (err: unknown, instance: ComponentPublicInstance | null, info: string) => {
		error('caught Vue error', { err, instance, info });
		throw err;
	};
}
