import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { ComputedRef, computed } from 'vue';

import { mapBy } from '@silae/helpers';
import { UserCompanyDTO } from '~/api';
import { CompanyTrackingContext } from '~/services';

import { useAuthenticationStore } from './authentication.store';

export type CompaniesStore = {
	companies: ComputedRef<Array<UserCompanyDTO>>;
	companiesById: ComputedRef<Map<number, UserCompanyDTO>>;
	employeeCompanies: ComputedRef<Array<UserCompanyDTO>>;
	employeeCompaniesById: ComputedRef<Map<number, UserCompanyDTO>>;
	getTrackingContext: (companyId: number) => CompanyTrackingContext;
	managedCompanies: ComputedRef<Array<UserCompanyDTO>>;
	managedCompaniesById: ComputedRef<Map<number, UserCompanyDTO>>;
	administeredCompanies: ComputedRef<Array<UserCompanyDTO>>;
	administeredCompaniesById: ComputedRef<Map<number, UserCompanyDTO>>;
};

export const useCompaniesStore = defineStore<'companies-store', CompaniesStore>('companies-store', () => {
	const { principal } = storeToRefs(useAuthenticationStore());

	const sortByName = (company: UserCompanyDTO, other: UserCompanyDTO) =>
		company?.companyName?.toLowerCase()?.localeCompare(other?.companyName?.toLowerCase());

	// companies
	const companies = computed(() => principal.value?.companies?.toSorted(sortByName) ?? []);
	const companiesById: ComputedRef<Map<number, UserCompanyDTO>> = computed(() => mapBy(companies.value, 'companyId'));
	const employeeCompanies = computed(() => companies.value.filter(company => company.isEmployee));
	const employeeCompaniesById: ComputedRef<Map<number, UserCompanyDTO>> = computed(() => mapBy(employeeCompanies.value, 'companyId'));
	const managedCompanies = computed(() => companies.value.filter(company => company.isManager));
	const managedCompaniesById: ComputedRef<Map<number, UserCompanyDTO>> = computed(() => mapBy(managedCompanies.value, 'companyId'));
	const administeredCompanies = computed(() => companies.value.filter(company => company.isAdmin));
	const administeredCompaniesById: ComputedRef<Map<number, UserCompanyDTO>> = computed(() =>
		mapBy(administeredCompanies.value, 'companyId')
	);

	const getTrackingContext = (companyId: number): CompanyTrackingContext => {
		const trackingContext = companiesById.value?.get(companyId)?.trackingContext;
		return trackingContext != null
			? {
					platform_id: trackingContext?.platformId,
					domain_id: trackingContext?.domainId,
					company_id: trackingContext?.companyId
				}
			: {};
	};

	return {
		companies,
		companiesById,
		employeeCompanies,
		employeeCompaniesById,
		getTrackingContext,
		managedCompanies,
		managedCompaniesById,
		administeredCompanies,
		administeredCompaniesById
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCompaniesStore, import.meta.hot));
