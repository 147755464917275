<template>
	<div class="simple-filter">
		<v-autocomplete
			v-model="values"
			class="simple-filter-autocomplete"
			clearable
			color="secondary"
			:data-test-id="`filter-${filter.key}`"
			:density="density"
			hide-details
			item-title="label"
			item-value="value"
			:items="options"
			multiple
			:open-text="filter.label"
			:placeholder="filter.label"
			return-object
			rounded
			variant="outlined"
		>
			<template #selection="{ item, index }">
				<span v-if="values.length === 1" class="simple-filter-autocomplete-selection-label">
					{{ item.title }}
				</span>
				<span v-else-if="index == 1" class="simple-filter-autocomplete-selection-label">
					{{ `(${values.length}) ${filter.label}` }}
				</span>
			</template>
			<template #no-data>
				<span class="ma-xs">{{ t('common.components.filter_select.no_result') }}</span>
			</template>
		</v-autocomplete>
	</div>
</template>

<style lang="scss" scoped>
.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.simple-filter {
	width: v-bind(width);

	&-autocomplete {
		:deep(.v-autocomplete__selection) {
			max-width: 100%;
		}

		:deep(.v-field--focused .v-autocomplete__selection) {
			max-width: 40%;
		}

		&-selection {
			&-label {
				@extend .text-ellipsis;
				max-width: calc(v-bind(width) * 0.75);
			}
		}

		:deep(.v-field__input) {
			flex-wrap: nowrap;
		}

		:deep(.v-field__clearable .v-icon) {
			width: 18px;
			height: 18px;
			font-size: 16px;
		}
	}
}
</style>

<script lang="ts" setup>
import { watchImmediate } from '@vueuse/core';
import { computed, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { VAutocomplete } from 'vuetify/components';

import { useModelValue } from '@silae/composables';
import { ListEntry, UiDensity } from '@silae/types';

import { Filter } from './filter.domain';

const { t } = useI18n();

const props = withDefaults(
	defineProps<{
		density?: UiDensity;
		width?: string;
		filter: Filter;
		modelValue?: Array<ListEntry>;
	}>(),
	{
		density: UiDensity.COMFORTABLE,
		width: '220px'
	}
);

const values = useModelValue(props);
const { filter } = toRefs(props);

const options = computed(() => filter.value.options.toSorted((a, b) => a.label.localeCompare(b.label)));

// add default values define in FilterConfiguration to values for pre-selected data
watchImmediate(filter, filter => {
	const _defaultValues = filter?.defaultValues;

	if (_defaultValues?.length > 0 && (values.value?.length ?? 0) === 0) {
		values.value = [..._defaultValues];
	}
});
</script>
