export class Stopwatch {
	private _startTime = new Date().getTime();
	private _time = 0;

	get time() {
		return this._time;
	}

	public reset() {
		this._startTime = new Date().getTime();
	}

	public stop() {
		this._time = new Date().getTime() - this._startTime;
		return this.time;
	}
}
