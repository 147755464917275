import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { Axios } from 'axios-observable';
import { defineStore } from 'pinia';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Ref, ref } from 'vue';

import { HttpService, RequestMethod } from './http.domain';

const defaultOnError$: (err: AxiosError) => Observable<any> = (err: AxiosError) => throwError(() => err);

/**
 * Get an Axios-based HTTP service singleton to connect to a backend.
 * The HTTP service methods generate RxJS observables.
 * @returns {
 *     @property http - the {@link HttpService}
 *     @property requestInterceptors - shortcut to the backend request interceptors
 *     @property responseInterceptors - shortcut to the backend response interceptors
 *     @function setBackendURL - backend URL setter
 *     @function setOnError$ - method to set the error handler RxJS observable
 * }
 */
export const useBackendHttpService = defineStore('backend-http-service', () => {
	const axiosInstance = Axios.create({});

	const setBackendURL = (baseURL: string) => (axiosInstance.defaults.baseURL = baseURL);
	const setWithCredentials = (withCredentials: boolean) => (axiosInstance.defaults.withCredentials = withCredentials);

	const requestInterceptors = axiosInstance.interceptors.request;
	const responseInterceptors = axiosInstance.interceptors.response;

	const _onErrorCallback$ = ref(defaultOnError$);
	const setOnError$ = (onError$: (err: AxiosError) => Observable<any>) => (_onErrorCallback$.value = onError$);

	const http = getHTTPService(axiosInstance, _onErrorCallback$);

	return { http, requestInterceptors, responseInterceptors, setBackendURL, setOnError$, setWithCredentials };
});

function makeRequest<ReturnType = any>(
	method: Method,
	axios: Axios,
	config: AxiosRequestConfig,
	onError$: (err: AxiosError) => Observable<any>,
	responseMapper: (res: AxiosResponse) => ReturnType
): Observable<ReturnType> {
	return axios.request({ ...config, method }).pipe(map(responseMapper), catchError(onError$));
}

const defaultResponseMapper = (res: AxiosResponse) => res.data;

function getHTTPService(instance: Axios, onError$: Ref<(err: AxiosError) => Observable<any>>): HttpService {
	return {
		delete: (url: string, config: AxiosRequestConfig = {}, responseMapper: (res: AxiosResponse) => any = defaultResponseMapper) =>
			makeRequest(RequestMethod.DELETE, instance, { ...config, url }, onError$.value, responseMapper),
		get: (url: string, config: AxiosRequestConfig = {}, responseMapper: (res: AxiosResponse) => any = defaultResponseMapper) =>
			makeRequest(RequestMethod.GET, instance, { ...config, url }, onError$.value, responseMapper),
		post: (
			url: string,
			data?: any,
			config: AxiosRequestConfig = {},
			responseMapper: (res: AxiosResponse) => any = defaultResponseMapper
		) => makeRequest(RequestMethod.POST, instance, { ...config, url, data }, onError$.value, responseMapper),
		put: (
			url: string,
			data?: any,
			config: AxiosRequestConfig = {},
			responseMapper: (res: AxiosResponse) => any = defaultResponseMapper
		) => makeRequest(RequestMethod.PUT, instance, { ...config, url, data }, onError$.value, responseMapper),
		patch: (
			url: string,
			data?: any,
			config: AxiosRequestConfig = {},
			responseMapper: (res: AxiosResponse) => any = defaultResponseMapper
		) => makeRequest(RequestMethod.PATCH, instance, { ...config, url, data }, onError$.value, responseMapper)
	};
}
