import posthog, { PostHog } from 'posthog-js';

// const directPostHogApiHost = 'https://eu.posthog.com';
const reversedProxyApiHost = 'https://sniffy.my.silae.fr';

export function getPostHogInstance(): PostHog {
	const api_host = reversedProxyApiHost;
	const token = import.meta.env.MS_POST_HOG_TOKEN;
	posthog.init(token, {
		api_host,
		// because we are an SPA, we manually handle $pageView tracking
		// we don't care about pageleave for now
		// disable auto tracking by posthog (all the more as it leaks some JWT when logging via URL)
		capture_pageview: false,
		capture_pageleave: false
	});
	return posthog;
}
