import { AxiosResponse } from 'axios';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { extractFilenameFromContentDispositionHeader } from '~/utils/download.utils.ts';

import { ADMIN_BASE_URL } from '../defs';
import { useBackendHttpService } from '../http';
import { FileContent, MedicalCheckupDTO, MedicalCheckupModuleStateDTO } from './medical-checkups.dto';

export function listServices$(companyId: number): Observable<Map<string, MedicalCheckupModuleStateDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${ADMIN_BASE_URL}/${companyId}/medical-checkups/services`);
}

export function activateMedispace$(companyId: number): Observable<void> {
	const { http } = useBackendHttpService();
	return http.post(`${ADMIN_BASE_URL}/${companyId}/medical-checkups/services/medispace/activate`);
}

export function listMedicalCheckups$(companyId: number): Observable<Array<MedicalCheckupDTO>> {
	const { http } = useBackendHttpService();
	return http.post(`${ADMIN_BASE_URL}/${companyId}/medical-checkups/_search`, {
		employeeIds: []
	});
}

export function downloadAttachmentFile$(companyId: number, medicalCheckup: MedicalCheckupDTO): Observable<FileContent> {
	const { http } = useBackendHttpService();
	return http
		.get(
			`${ADMIN_BASE_URL}/${companyId}/medical-checkups/${medicalCheckup.id}/attachment`,
			{ responseType: 'arraybuffer' },
			(res: AxiosResponse) => res
		)
		.pipe(
			map(axiosResponse => {
				const filename = extractFilenameFromContentDispositionHeader(axiosResponse);
				return {
					filename: filename,
					data: axiosResponse.data,
					format: filename.split('.').pop()
				};
			})
		);
}
