import { Observable } from 'rxjs';
import { ComputedRef } from 'vue';

import {
	EmployeeIDJobID,
	EmployeeInformationDTO,
	EmployeeVariableElementsDetailsAndDefinitions,
	ISODateString,
	VariableElementCode,
	VariableElementDefinitionDTO,
	VariableElementDetailsDTO
} from '~/api';

export enum VariableElementType {
	BONUSES = 'bonuses',
	HOURS = 'hours'
}

export type VariableElementDefinition = VariableElementDefinitionDTO & {
	placeholder?: string;
	validation?: RegExp;
};

export type PrettyEmployeeWithVariableElements<T = EmployeeInformationDTO> = T & {
	id: EmployeeIDJobID;
	fullName: string;
};

export interface VariableElements {
	definitions: ComputedRef<Array<VariableElementDefinition>>;
	employees: ComputedRef<Array<PrettyEmployeeWithVariableElements>>;
	fetchVariableElements$: (
		companyId: number,
		period: ISODateString,
		employeeIds: Array<number>,
		invalidateCache?: boolean
	) => Observable<EmployeeVariableElementsDetailsAndDefinitions>;
	variableDetailsPerEmployeeId: ComputedRef<Map<EmployeeIDJobID, Map<VariableElementCode, VariableElementDetailsDTO>>>;
}
