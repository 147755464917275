import { lastValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RouteLocationNamedRaw, RouteLocationNormalized, RouteLocationNormalizedGeneric, createRouter, createWebHistory } from 'vue-router';

import { warn } from '@silae/helpers';
import { showMobileAppBanner, useGuardedRoutes } from '~/composables';
import { EmailCampaignContext } from '~/domain';
import { Devices } from '~/plugins';
import { useTrackingService } from '~/services';
import { useAuthenticationStore, useRolesStore } from '~/stores';
import { decodeBase64URL } from '~/utils';

import { stripLocationQueryParams } from './router.utils';
import { routes } from './routes';
import { RouteContext, RouteContextByKey, RouteKeys } from './routes.domain';

export enum RedirectionCauseEnum {
	USER_NOT_AUTHENTICATED,
	FORBIDDEN
}

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes
});

router.beforeEach(async (to, from, next) => {
	const authenticationStore = useAuthenticationStore();
	const { isForbidden } = useGuardedRoutes();

	const isAuthenticationRequired = (to: RouteLocationNormalized) => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			if (to.meta.requiresAuth && !authenticationStore.isAuthenticated) {
				const principal = await lastValueFrom(authenticationStore.refreshPrincipal$().pipe(catchError(() => of(null))));
				if (principal != null) {
					resolve(true);
				} else {
					reject(RedirectionCauseEnum.USER_NOT_AUTHENTICATED);
				}
			} else {
				resolve(true);
			}
		});
	};

	const isAllowed = (to: RouteLocationNormalized) => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			if (!isForbidden(to)) {
				resolve(true);
			} else {
				reject(RedirectionCauseEnum.FORBIDDEN);
			}
		});
	};

	try {
		await isAuthenticationRequired(to);
		await isAllowed(to);

		// in case of an email campaign, once user is authenticated, track and reroute
		const targetRoute = authenticationStore.isAuthenticated ? emailCampaignCheckpoint(to) : undefined;

		// in case of a nullish targetRoute, continue with routing
		next(targetRoute);
	} catch (error) {
		switch (error as RedirectionCauseEnum) {
			case RedirectionCauseEnum.USER_NOT_AUTHENTICATED: {
				const redirect = to.path !== '/' ? to.path : null;
				const query = redirect != null ? { redirect, ...to.query } : to.query;
				next({ name: RouteKeys.SIGN_IN, query });
				break;
			}
			case RedirectionCauseEnum.FORBIDDEN:
				next({ name: RouteKeys.HOME, query: to.query });
				break;
		}
	}
});

router.afterEach((to, from, failure) => {
	if (!failure) {
		useTrackingService().trackPageView();
		if (Devices().isIOS || Devices().isAndroid) {
			showMobileAppBanner();
		}
	}
});

/**
 * trigger routing related tracking:
 * - page views
 * - email campaign
 * @param to
 */
function emailCampaignCheckpoint(to: RouteLocationNormalizedGeneric): RouteLocationNamedRaw | undefined {
	// track an email campaign if there is an encoded context
	// "definitely not a tracker"
	const serializedEmailCampaignContext = to.query?.dnat as string;

	if (serializedEmailCampaignContext != null) {
		const decodedContext = decodeBase64URL(serializedEmailCampaignContext);
		const emailCampaignContext: EmailCampaignContext = JSON.parse(decodedContext);
		const routeContext = setupEmailCampaignRouteContext(emailCampaignContext);
		useTrackingService().trackEmailCampaign(emailCampaignContext);
		// once email campaign has been tracked, and reroute is ready, remove param from query
		return { name: routeContext.name, query: stripLocationQueryParams(to.query, ['dnat']) };
	}
}

/**
 * email campaign contains a route key so we can reroute users within the app
 * this route can require a specific context that we need to set up to bypass route guards
 * @param emailCampaignContext
 */
function setupEmailCampaignRouteContext(emailCampaignContext: EmailCampaignContext): RouteContext {
	const context: RouteContext = RouteContextByKey?.[emailCampaignContext.route];

	if (context == null) {
		warn('expected route is null');
		return;
	}

	const rolesStore = useRolesStore();
	// quick fail when role is not available
	if (!rolesStore.availableRoles.includes(context.role)) return;

	// set expected role
	if (rolesStore.activeRole !== context.role) {
		rolesStore.setActiveRole(context.role);
	}

	return context;
}

export { router };
