import { Observable } from 'rxjs';

import { COMPANY_BASE_URL, MANAGER_BASE_URL } from '~/api/defs';
import { useBackendHttpService } from '~/api/http';

import { CompanyInformationDTO, EmployeeCompanyInformationDTO } from './information.dto';

const API = 'information';

export function listEmployeeCompaniesInformation$(companyIds: Array<number>): Observable<Array<EmployeeCompanyInformationDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${API}?companies=${companyIds.join(',')}`);
}

export function listManagedCompaniesInformation$(companyIds: Array<number>): Observable<Array<CompanyInformationDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${MANAGER_BASE_URL}/${API}?companies=${companyIds.join(',')}`);
}
